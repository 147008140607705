/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { organization } from "../config";


const CustomCrumbScript = ({ crumbs, crumbLabel, siteUrl }) => {

  if (crumbLabel) {
    crumbs[crumbs.length - 1]['crumbLabel'] = crumbLabel
  }
  const itemList = crumbs.map((item, index) => (
    {
      "@type": "ListItem",
      position: index + 1,
      name: item.crumbLabel,
      item: `${siteUrl}/${item.pathname}`
    }
  )
  )

  return (

    
      JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: itemList
      })
    

  )
}

const Organization = ({ url }) => {
  const items = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": organization.name,
    "legalName": organization.legalName,
    "url": url,
    "logo": "https://prochoice.com.tr/images/logo-black.png",
    "foundingDate": organization.foundingDate,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": organization.address.streetAddress,
      "addressLocality": organization.address.addressLocality,
      "addressRegion": organization.address.addressRegion,
      "postalCode": organization.address.postalCode,
      "addressCity": organization.address.addressCity,
      "addressCountry": organization.address.addressCountry
    },
    "contactPoint": {
      "@type": organization.contactPoint.type,
      "contactType": organization.contactPoint.contactType,
      "telephone": organization.contactPoint.telephone,
      "email": organization.contactPoint.email
    },
    "sameAs": organization.sameAs
  }

  return (
    JSON.stringify(items)



  )
}

const BlogPosting = ({headline, url, datePublished, description, author}) => {
  const items = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline":headline,
    "url": url,
    "datePublished":datePublished,
    "description": description,
    "author": {
      "@type":"Person",
      "name":author
    }

  }

  return (
    JSON.stringify(items)
  )
}



function SEO({ description, lang, meta, title, location, crumbs, crumbLabel,blogMeta }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const siteUrl = site.siteMetadata.siteUrl || null
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const url = `${siteUrl}${location.pathname}`

  return (

    <Helmet
      htmlAttributes={{
        lang,
      }}

      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}

    >
      <title>{title}</title>
      <meta name='description' content={metaDescription} />
      <meta name='og:title' content={title} />
      <meta name='og:description' content={metaDescription} />
      <meta name='og:type' content='website' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:creator' content={site.siteMetadata?.author || ``} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDescription} />
      <link rel='canonical' href={url} />
      {crumbs &&
        <script type="application/ld+json">
          {CustomCrumbScript({ crumbs: crumbs, crumbLabel: crumbLabel, siteUrl: siteUrl })}
        </script>
      }
      <script type="application/ld+json">
        {Organization({ url: url })}
      </script>
      {blogMeta &&
        <script type="application/ld+json">
          {BlogPosting(blogMeta)}
        </script>
      }
      
      

    </Helmet>


  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
